import React, { ReactNode } from "react";
import {
  BiAlignLeft,
  BiFile,
  BiTime,
  BiBarChartAlt,
  BiDownload,
  BiAlignJustify,
  BiBox,
  BiDollar,
  BiUpload
} from "react-icons/bi";
import { BsTruck } from 'react-icons/bs'
import { RoutePermittedRole } from "../shared/constants/AppConst";

interface RoutePagesInterface {
  vehicles: boolean;
  pim: boolean;
  storeAdmin: boolean;
}

export const RoutesPagesConfig: RoutePagesInterface = {
  pim: process.env?.REACT_APP_PAGES_PIM === "true",
  vehicles: process.env?.REACT_APP_PAGES_VEHICLES === "true",
  storeAdmin: false,
}

const profileData = JSON.parse(localStorage.getItem("profile"));
console.info(`Profile ${JSON.stringify(profileData)}`);
if (profileData?.groups) {
  RoutesPagesConfig.storeAdmin = profileData.groups.filter(g => g.name === 'ADMIN_STORE').length > 0;
}

export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  permittedRole?: RoutePermittedRole;
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
}

// @ts-ignore
const routesConfig: RouterConfigData[] = [
  {
    id: 'orders',
    title: 'Order',
    messageId: 'sidebar.orders',
    type: 'collapse',
    permittedRole: RoutePermittedRole.Admin,
    // icon: 'dashboard',
    children: [
      {
        id: "stock",
        title: "Stock",
        messageId: "orders.store.customerOrders",
        type: "item",
        icon: <BiDollar />,
        url: "/orders/customerOrders",
      },
      {
        id: "stock",
        title: "Offer",
        messageId: "orders.store.offers",
        type: "item",
        icon: <BiDollar />,
        url: "/orders/offers",
      },
      {
        id: "stock",
        title: "Stock",
        messageId: "orders.store.orders",
        type: "item",
        icon: <BiDollar />,
        url: "/orders/pos",
      }
    ]
  },
  RoutesPagesConfig.vehicles && {
    id: 'workshop',
    title: 'workshop',
    messageId: 'orders.store.workshop',
    type: 'collapse',
    permittedRole: RoutePermittedRole.Admin,
    // icon: 'dashboard',
    children: [
      {
        id: "stock",
        title: "Workshop",
        messageId: "orders.store.workshop",
        type: "item",
        icon: <BiDollar />,
        url: "/orders/workshop",
      }
    ]
  },
  {
    id: 'purchases',
    title: 'Purchase',
    messageId: 'sidebar.purchase',
    type: 'collapse',
    permittedRole: RoutePermittedRole.Admin,
    // icon: 'dashboard',
    children: [
      {
        id: "purchaseOrder",
        title: "Purchase Order",
        messageId: "sidebar.purchase.purchaseOrder",
        type: "item",
        icon: <BiDollar />,
        url: "/purchases/purchaseOrder",
      },
      {
        id: "reconcilication",
        title: "Reconciliation",
        messageId: "sidebar.purchase.reconciliation",
        type: "item",
        icon: <BiDollar />,
        url: "/purchases/reconciliation",
      },
      {
        id: "suppliers",
        title: "Page 5",
        messageId: "warehouse.suppliers",
        type: "item",
        icon: <BiAlignLeft />,
        url: "/purchase/suppliers",
      }
    ]
  },
  {
    id: 'warehouse',
    title: 'Lager',
    messageId: 'sidebar.warehouse',
    type: 'collapse',
    permittedRole: RoutePermittedRole.Admin,
    // icon: 'dashboard',
    children: [
      {
        id: "whstart",
        title: "Start",
        messageId: "warehouse.stock.start",
        type: "item",
        icon: <BiBarChartAlt />,
        url: "/warehouse/start",
      },
      {
        id: "stock",
        title: "Stock",
        messageId: "warehouse.stock.stocking",
        type: "item",
        icon: <BiBox />,
        url: "/warehouse/stock",
      },
      {
        id: "incoming",
        title: "Incoming",
        messageId: "warehouse.stock.delivery1",
        type: "item",
        icon: <BiUpload />,
        url: "/orders/OrderDelivery",
      },
      {
        id: "pack",
        title: "Pack",
        messageId: "warehouse.stock.pack",
        type: "item",
        icon: <BsTruck />,
        url: "/orders/pack",
      },
      {
        id: "incoming",
        title: "Incoming",
        messageId: "warehouse.stock.delivery",
        type: "item",
        icon: <BiDownload />,
        url: "/orders/delivery",
      },
      {
        id: "transactions",
        title: "Transaktioner",
        messageId: "statistics.transactions",
        type: "item",
        icon: <BiAlignJustify />,
        url: "/warehouse/transactions",
      },
      {
        id: "stocktake",
        title: "stocktake",
        messageId: "statistics.stocktake",
        type: "item",
        icon: <BiAlignJustify />,
        url: "/warehouse/stocktake",
      },

      {
        id: "locations",
        title: "Locations",
        messageId: "warehouse.stock.locations",
        type: "item",
        icon: <BiAlignJustify />,
        url: "/warehouse/locations",
      }
    ]
  },
  {
    id: "article",
    title: "Artiklar",
    messageId: "sidebar.articles",
    type: "collapse",
    permittedRole: RoutePermittedRole.Admin,
    children: [
      {
        id: "artiklar",
        title: "Articles",
        messageId: "articles.articles",
        type: "item",
        url: "/articles/article",
      },
      {
        id: "priser",
        title: "Pricer",
        messageId: "articles.prices",
        type: "item",
        icon: <BiAlignLeft />,
        url: "/articles/prices",
      },
      {
        id: "page-6",
        title: "Page 6",
        messageId: "sidebar.pim.brand",
        type: "item",
        icon: <BiAlignLeft />,
        url: "/pim/brand",
      },
    ]
  },
  RoutesPagesConfig.vehicles && {
    id: "vehicles",
    title: "Fordon",
    messageId: "sidebar.vehicles",
    type: "collapse",
    permittedRole: RoutePermittedRole.Admin,
    children: [
      {
        id: "usedVehicles",
        title: "Incoming",
        messageId: "warehouse.stockUsedVehicles",
        type: "item",
        icon: <BiDownload />,
        url: "/warehouse/usedVehicles",
      },
      /*{
          id: "page-4",
          title: "Page 4",
          messageId: "sidebar.pim.vehicles",
          type: "item",
          icon: <BiAlignLeft />,
          url: "/pim/vehicles",
      }*/
    ]
  },
  RoutesPagesConfig.storeAdmin && {
    id: "statistics",
    title: "Statistik",
    messageId: "sidebar.statistics",
    type: "collapse",
    permittedRole: RoutePermittedRole.Admin,
    children: [
      {
        id: "statistics",
        title: "Statistik",
        messageId: "statistics.statistics",
        type: "item",
        url: "/statistics/start",
      }
      // ,
      // {
      //   id: "statistics",
      //   title: "Rapporter",
      //   messageId: "statistics.reports",
      //   type: "item",
      //   url: "/reports/start",
      // }
    ]
  },
  {
    id: "pos",
    title: "POS",
    messageId: "store.pos",
    type: "collapse",
    permittedRole: RoutePermittedRole.Admin,
    children: [
      {
        id: "pos",
        title: "POS",
        messageId: "store.pos",
        type: "item",
        url: "/store/pos",
      },
      {
        id: "cashreport",
        title: "CashReport",
        messageId: "store.report",
        type: "item",
        url: "/store/report",
      },
      {
        id: "customers",
        title: "Customers",
        messageId: "store.customers",
        type: "item",
        url: "/store/customers",
      }
    ]
  },
  {
    id: "web",
    title: "Web",
    messageId: "sidebar.web",
    type: "collapse",
    permittedRole: RoutePermittedRole.Admin,
    children: [
      {
        id: "openhours",
        title: "Öppettider",
        messageId: "sidebar.web.openhours",
        type: "item",
        icon: <BiTime />,
        url: "/web/openhours",
      },
      {
        id: "banners",
        title: "Banners",
        messageId: "sidebar.web.banners",
        type: "item",
        icon: <BiFile />,
        url: "/web/banners",
      },
      {
        id: "content",
        title: "Content",
        messageId: "sidebar.web.content",
        type: "item",
        icon: <BiFile />,
        url: "/web/content",
      }
    ],
  },
  RoutesPagesConfig.pim && {
    id: "pim",
    title: "Pim",
    messageId: "sidebar.pim",
    type: "collapse",
    permittedRole: RoutePermittedRole.PIM,
    children: [
      {
        id: "page-1",
        title: "Page 1",
        messageId: "sidebar.pim.category",
        type: "item",
        icon: <BiAlignLeft />,
        url: "/pim/category",
      },
      {
        id: "page-2",
        title: "Import",
        messageId: "sidebar.pim.import",
        type: "item",
        icon: <BiAlignLeft />,
        url: "/pim/import",
      },
      {
        id: "page-3",
        title: "Page 3",
        messageId: "sidebar.pim.imported",
        type: "item",
        icon: <BiAlignLeft />,
        url: "/pim/imported",
      },
      {
        id: "page-4",
        title: "Page 4",
        messageId: "sidebar.pim.vault",
        type: "item",
        icon: <BiAlignLeft />,
        url: "/pim/products",
      },
      {
        id: "page-51",
        title: "Page 51",
        messageId: "sidebar.pim.typeSelect",
        type: "item",
        icon: <BiAlignLeft />,
        url: "/pim/typeSelect",
      },
      {
        id: "page-5",
        title: "Page 5",
        messageId: "sidebar.pim.imageSelect",
        type: "item",
        icon: <BiAlignLeft />,
        url: "/pim/imageSelect",
      },
      {
        id: "page-6",
        title: "Page 6",
        messageId: "sidebar.pim.productType",
        type: "item",
        icon: <BiAlignLeft />,
        url: "/pim/productTypes",
      },
      {
        id: "page-7",
        title: "Page 7",
        messageId: "sidebar.pim.categoryRef",
        type: "item",
        icon: <BiAlignLeft />,
        url: "/pim/categoryRef",
      },
      {
        id: "page-8",
        title: "Page 8",
        messageId: "sidebar.pim.brand",
        type: "item",
        icon: <BiAlignLeft />,
        url: "/pim/brand",
      },
      {
        id: "page-9",
        title: "Page 9",
        messageId: "sidebar.pim.attribute",
        type: "item",
        icon: <BiAlignLeft />,
        url: "/pim/attributes",
      },
      {
        id: "page-10",
        title: "Page 10",
        messageId: "sidebar.pim.attributeConfiguration",
        type: "item",
        icon: <BiAlignLeft />,
        url: "/pim/attributesConfiguration",
      },
    ],
  }
];
export default routesConfig;
